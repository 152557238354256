<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#/base/personDeviceLog/punchList">测温台账</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <el-row>
        <el-form-item label="日期" prop="queryTime">
          <el-date-picker
            size="mini"
            v-model="queryModel.queryTime"
            type="date"
            placeholder="选择日期"
            style="width:100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属班级" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
          ></el-select-tree>&nbsp;&nbsp;
          <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="姓名" prop="personName">
          <el-input type="text" size="mini" v-model="queryModel.personName"></el-input>
        </el-form-item>
        <el-form-item label="人员类型" prop="popedom">
          <el-select size="mini" v-model="queryModel.popedom" style="width:100px;">
            <el-option label="学生" value="1"></el-option>
            <el-option label="教职工" value="3,4,9"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="健康情况" prop="healthStatus">
          <el-select
            v-model="queryModel.healthStatus"
            filterable
            placeholder="请选择"
            style="width:50%"
          >
            <el-option
              size="mini"
              v-for="result in healthData"
              :key="result.id"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="handleQuery"
            :loading="loading"
          >查询</el-button>&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
          >重置</el-button>&nbsp;
        </el-form-item>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="exportXls"
      >导出健康信息台账</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      style="min-height:400px;"
      :height="tableHeight"
      v-loading="loading"
      :element-loading-text="loadingText"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMethod" width="100"></el-table-column>
      <el-table-column prop="name" label="学生名称" width="150"></el-table-column>
      <el-table-column prop="companyName" label="校园" width="220"></el-table-column>
      <el-table-column prop="situation" label="测温情况" width="500">
        <template slot-scope="{row}">
          <span v-if="row.situation != ''">{{row.situation}}</span>
          <span v-else>未测温</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <personHealthLedger-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></personHealthLedger-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import personHealthLedgerApi from "@/api/student/personHealthLedger";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import personDeviceLogApi from "@/api/base/personDeviceLog";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import pageUtil from "@/utils/page";
export default {
  name: "StudentPersonHealthLedgerList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        queryTime: [{ required: true, message: "请选择时间", trigger: "blur" }]
      },
      queryModel: {
        personName: "",
        healthStatus: "",
        companyId: "",
        subordinate: true,
        queryTime: "",
        popedom: "1"
      },
      loading: false,
      loadingText:"加载中",
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      healthData: [],
      companyResult: [],
      treeData: [],
      tableHeight: 400,
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      downloadLoading: false
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("catalogName", "健康情况");
    dataDictionaryApi.findByCatalogName(formData).then(response => {
      var jsonData = response.data;
      this.healthData = jsonData.data;
    });

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.loadTree();
    this.setDefaultTime();

    setTimeout(() => {
      //45为分页栏的高度
      //页面高度-列表上面的高度-分页栏高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    }, 1000);
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personName", self.queryModel.personName);
      formData.append("healthStatus", self.queryModel.healthStatus);
      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("queryTime", self.queryModel.queryTime);
      formData.append("popedom", self.queryModel.popedom);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personDeviceLogApi
        .punchSearch(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleQuery() {
      var self = this;
      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          self.changePage(1);
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      //this.queryModel.companyId = "";
      this.queryModel.queryTime = "";
      this.queryModel.subordinate = false;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    setDefaultTime() {
      var self = this;
      var date = new Date();
      var month = parseInt(date.getMonth() + 1);
      var nowTime = date.getFullYear() + "-" + month + "-" + date.getDate();

      self.queryModel.queryTime = nowTime;
    },
    exportXls() {
      var self = this;

      //导出
      this.$refs["queryForm"].validate(valid => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();
          formData.append("pageIndex", self.pageIndex);
          formData.append("pageSize", self.totalPages);

          formData.append("personName", self.queryModel.personName);
          formData.append("healthStatus", self.queryModel.healthStatus);
          if (self.queryModel.companyId == null) {
            self.queryModel.companyId = "";
          }
          formData.append("companyId", self.queryModel.companyId);
          formData.append("subordinate", self.queryModel.subordinate);
          formData.append("queryTime", self.queryModel.queryTime);
          formData.append("popedom", self.queryModel.popedom);

          var timestamp = new Date().getTime();
          formData.append("timestamp", timestamp);

          personDeviceLogApi.exportPunchXls(formData).then(function(response) {
            var jsonData = response.data;

            self.downloadLoading = false;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                type: "success",
                showClose: true,
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
          //定时查询导出进度
          this.queryExportProgress(timestamp);
        }
      });
    },
    queryExportProgress(timestamp) {
        if(this.loading) {
          personDeviceLogApi.exportProgress(timestamp).then(resp=>{
            var jsonData = resp.data;

            if(jsonData.result){
              this.loadingText = jsonData.message + "";
            }

            setTimeout(()=>{
              this.queryExportProgress(timestamp)
            },1000);
          })
        }
    },
  },
  mounted: function() {
    //this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style>
.el-table .cell {
  white-space: pre-line; /*保留换行符*/
}
</style>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>