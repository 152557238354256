import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/personHealthLedger/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/personHealthLedger/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXls", formData);
}

function pageListAll(formData) {
  //包含测温和健康上报
  return request.post(constant.serverUrl + "/base/personHealthLedger/pageListAll", formData);
}

function exportXlsAll(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsAll", formData);
}

function exportXlsWC(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsWC", formData);
}

function exportXlsTWYC(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsTWYC", formData);
}

function exportXlsZZYC(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsZZYC", formData);
}

function gpsHistoryList(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/gpsHistoryList", formData);
}

function healthLedgerDateExportXls(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/healthLedgerDateExportXls", formData);
}

function pageListNew(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/pageListNew", formData);
}

function exportXlsHJR(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsHJR", formData);
}

function exportXlsHJJKJC(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsHJJKJC", formData);
}

function exportXlsDAYNumber(formData) {
  return request.post(constant.serverUrl + "/base/personHealthLedger/exportXlsDAYNumber", formData);
}


export default {
  pageList, create, edit, add, update, remove, batchRemove, exportXls, pageListAll,
  exportXlsAll,gpsHistoryList,exportXlsWC,exportXlsTWYC,exportXlsZZYC,pageListNew,healthLedgerDateExportXls,
  exportXlsHJR,exportXlsHJJKJC,exportXlsDAYNumber
}