import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/personDeviceLog/pageList", formData);
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/personDeviceLog/exportXls", formData);
}

function exportProgress(timestamp) {
  //导出xls
  return request.get(constant.serverUrl + `/personDeviceLog/exportProgress?timestamp=${timestamp}`);
}

function fixPerson(recordId){
  var formData = new FormData();
  formData.append("recordId",recordId);

  return request.post(constant.serverUrl + "/personDeviceLog/fixPerson", formData);
}

//测温台账
function punchSearch(formData){
  return request.post(constant.serverUrl + "/personDeviceLog/punchSearch", formData);
}

//导出测温台账
function exportPunchXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/personDeviceLog/exportPunchXls", formData);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/personDeviceLog/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/personDeviceLog/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,exportXls,exportProgress,fixPerson,punchSearch,exportPunchXls,batchRemove,update
}